<template>
  <div class="ResignationRelatedInformation">
    <div class="topTitle">
      <div class="title">招聘配额<span class="boldTitle"> / 离职</span></div>
      <div class="bottomButton">
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
    <descriptions :descriptionData="descriptionData" />
    <PGTitle title="附件信息">
      <pgUploadFile :id="id" type="Leave" :upload-list-call="uploadListCall" />
    </PGTitle>
    <PGTitle title="离职信息">
      <FormDesigner ref="FormDesignerRef" :formScale="formScale" />
    </PGTitle>
  </div>
</template>

<script>
import descriptions from '@/components/descriptions/descriptions'
import PGTitle from '@/components/PG-title/PG-title'
import pgUploadFile from '@/components/pg-uploadFile/pg-uploadFile'
import FormDesigner from '@/components/FormDesigner/FormDesigner'
import { getCodeLeaveValue, getUserLeaveInfo, saveLeaveForm } from '@/api/quto'
import { mergeDesDataOfPort } from '@/utils/util'

export default {
  name: 'ResignationRelatedInformation',
  components: {
    descriptions,
    PGTitle,
    pgUploadFile,
    FormDesigner
  },
  created() {
    this.id = this.$route.query.id
    this.queryApi()
  },
  data() {
    return {
      formScale: [
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'resignationSubmissionDate',
                rules: '',
                label: 'Resignation Submission Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lastWorkingDate',
                rules: '',
                label: 'Last Working Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'lastEmploymentDate',
                rules: '',
                label: 'Last Employment Date',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'socialInsuranceHousingFundPaidTill',
                rules: '',
                label: 'Social Insurance/ Housing Fund paid till',
                id: Math.random(),
                clearable: true,
                valueFormat: 'yyyy-MM-dd',
                placeholder: '请选择',
                type: 'data',
                value: '',
                change: () => {}
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Paid Out Annual Leave Days',
                prop: 'paidOutAnnualLeaveDays',
                type: 'inputNumber',
                rules: '',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'overtakenAnnualLeaveDays',
                rules: '',
                label: 'Overtaken Annual Leave Days',
                id: Math.random(),
                placeholder: '请选择',
                type: 'inputNumber',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'overtakenSickLeaveDays',
                rules: '',
                label: 'Overtaken Sick Leave Days',
                id: Math.random(),
                type: 'inputNumber',
                value: ''
              }
            },
            {
              span: 6,
              id: Math.random(),
              formData: {
                prop: 'destinationProfileWhereDidTheVoluntaryLeaversGo',
                rules: '',
                tooltip: true,
                label:
                  'Destination profile - Where did the Voluntary leavers go?',
                id: Math.random(),
                type: 'input',
                value: ''
              }
            }
          ]
        },
        {
          id: Math.random(),
          gutter: 20,
          colList: [
            {
              span: 6,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'Leave Type- Leave Reason',
                prop: 'leaveTypeLeaveReason',
                type: 'select',
                options: [],
                rules: '',
                value: '',
                clearable: true,
                filterable: true,
                placeholder: '请选择',
                onClick: () => {},
                change: () => {}
              }
            },
            {
              span: 18,
              id: Math.random(),
              formData: {
                id: Math.random(),
                label: 'remark',
                prop: 'remark',
                type: 'input',
                options: [],
                rules: 'required',
                value: '',
                placeholder: '请输入'
              }
            }
          ]
        }
      ],
      descriptionData: [
        {
          title: '基础信息',
          content: [
            {
              label: 'English Name',
              key: 'englishName',
              value: ''
            },
            {
              label: 'Chinese Name',
              key: 'chineseName',
              value: ''
            },
            {
              label: 'Lion ID',
              key: 'lionId',
              value: ''
            },
            {
              label: 'Name of Bank',
              key: 'nameOfBank',
              value: ''
            },
            {
              label: 'Bank Account No.',
              key: 'bankAccountNo',
              value: ''
            }
          ]
        }
      ],
      id: '',
      uploadListCall: []
    }
  },
  methods: {
    async submit() {
      const data = await this.$refs.FormDesignerRef.getValue()
      const returnData = {
        id: this.id,
        ...data
      }
      saveLeaveForm(returnData).then((res) => {
        if (res && res.code === 200) {
          this.$message.success('离职成功')
          this.cancel()
        }
      })
    },
    cancel() {
      this.$router.push({
        path: '/employmentApplication/quotaManagement'
      })
    },
    queryApi() {
      getCodeLeaveValue().then((res) => {
        if (res && res.code === 200) {
          const data = {
            leaveTypeLeaveReason: res.data.map((item) => {
              return {
                label: item.label,
                value: item.value
              }
            })
          }
          this.$refs.FormDesignerRef.setOptions(data)
        }
      })
      const data = {
        id: this.id
      }
      getUserLeaveInfo(data).then((res) => {
        if (res && res.code === 200) {
          mergeDesDataOfPort(this.descriptionData, res.data)
          this.uploadListCall = res.data.files
          this.$refs.FormDesignerRef.setValue(res.data)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ResignationRelatedInformation {
  background: white;
  padding: 20px;
  position: relative;
  .bottomButton {
    text-align: center;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .topTitle {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 20px;
    margin-bottom: 20px;
    .title {
      font-size: 21px;
      font-weight: 400;
      .boldTitle {
        font-size: 21px;
        font-weight: 500;
        color: #303133;
      }
    }
  }
}
</style>
