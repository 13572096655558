import { render, staticRenderFns } from "./ResignationRelatedInformation.vue?vue&type=template&id=d6d4638e&scoped=true"
import script from "./ResignationRelatedInformation.vue?vue&type=script&lang=js"
export * from "./ResignationRelatedInformation.vue?vue&type=script&lang=js"
import style0 from "./ResignationRelatedInformation.vue?vue&type=style&index=0&id=d6d4638e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6d4638e",
  null
  
)

export default component.exports